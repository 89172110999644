export const copyText = (value: any) => {
  // Create the element containing the text
  const textElement = document.createElement('div')
  textElement.id = `${value}`
  textElement.innerText = `${value}`
  document.body.appendChild(textElement)

  // Get the text to copy
  const text = textElement.innerText

  // Create a temporary textarea element
  const tempInput = document.createElement('textarea')
  tempInput.style.position = 'absolute'
  tempInput.style.left = '-1000px'
  tempInput.value = text
  document.body.appendChild(tempInput)

  // Select the text in the textarea
  tempInput.select()
  tempInput.setSelectionRange(0, 99999) // For mobile devices

  // Copy the selected text to the clipboard
  document.execCommand('copy')

  // Remove the temporary elements
  document.body.removeChild(tempInput)
  document.body.removeChild(textElement)
}
