import { memo } from 'react'

import MenuItem from '@mui/material/MenuItem'

import { ISelectOption } from '@appTypes/global'

import { StyledMuiSelect } from './styled'

interface ISelect {
  title: string
  options: ISelectOption[]
  selectedOption: ISelectOption | null
  setState: (option: ISelectOption) => void
}

const FormSelect = ({ title, options, selectedOption, setState }: ISelect) => {
  return (
    <StyledMuiSelect
      id={`select-${title}`}
      value={selectedOption}
      defaultValue={selectedOption}
      renderValue={(selected: any) => {
        return selected?.name
      }}
      onChange={(event) => {
        const found = options.find((item) => item.value === event.target.value)

        setState(found || options[0])
      }}
    >
      {options?.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        )
      })}
    </StyledMuiSelect>
  )
}

export default memo(FormSelect)
