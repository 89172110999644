import { COLORS } from '@collections/colors'

const MuiInputLabel = {
  styleOverrides: {
    root: {
      position: 'relative',
      transform: 'none',
      marginBottom: '7px',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '16px',
      color: COLORS.raisinBlack2
    } as any
  }
}

export default MuiInputLabel
