import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Wrapper = styled(Box)(({ theme }) => ({
  marginTop: '40px',
  display: 'grid',
  gridGap: '40px',
  gridTemplateColumns: 'repeat( auto-fit, 400px )',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat( auto-fit, minmax(400px, 1fr) )'
  }
}))
