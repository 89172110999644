import { ChangeEvent, Dispatch, memo, SetStateAction } from 'react'
import { useSearchParams } from 'react-router-dom'

import SearchSvg from '@assets/svgs/search.svg'
import CloseSvg from '@assets/svgs/close.svg'

import Typography from '@mui/material/Typography'

import type { IFiltersProps, IOption } from '@appTypes/model/filters.model'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'

import { filterItems } from '@components/Filters'
import { Search } from '@components/common/Inputs'
import Suggestion from '../Suggestion'
import { FilterItem } from '../../styled'

interface ISimpleFiltersSwitcher {
  page: string
  members: IOption[]
  inputValue: string
  placeholder: string
  selected: IOption | null
  searchByKey: string
  handleSelectChange: (data: IFiltersProps) => void
  setInputValue: Dispatch<SetStateAction<string>>
  setSearchByKey: Dispatch<SetStateAction<string>>
  setSelected: Dispatch<SetStateAction<IOption | null>>
}

const SimpleFiltersSwitcher = ({
  page,
  members,
  inputValue,
  placeholder,
  selected,
  searchByKey,
  setInputValue,
  setSelected,
  setSearchByKey,
  handleSelectChange
}: ISimpleFiltersSwitcher) => {
  const [searchParams] = useSearchParams()

  switch (page) {
    case PAGE_ROUTES_PRIVATE.All_COURSES:
    case PAGE_ROUTES_PRIVATE.ALL_MEMBERS:
    case PAGE_ROUTES_PRIVATE.All_CLUBS:
      return (
        <Search
          placeholder={placeholder}
          value={searchByKey}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchByKey(e.target.value || '')
          }}
          endAdornment={
            !searchByKey ? (
              <img src={SearchSvg} alt='Search' loading='lazy' />
            ) : (
              <img
                src={CloseSvg}
                alt='Close'
                loading='lazy'
                onClick={() => {
                  setSearchByKey('')
                }}
              />
            )
          }
        />
      )
    case PAGE_ROUTES_PRIVATE.OPEN_TICKETS:
    case PAGE_ROUTES_PRIVATE.TICKET_HISTORY:
      return (
        <>
          {filterItems.map((item) => {
            const active = searchParams.get(item.key) === item.value

            return (
              <FilterItem
                key={item.value}
                active={active ? 'active' : ''}
                onClick={() => handleSelectChange({ [item.key]: item.value })}
              >
                <Typography component='p'>{item.title}</Typography>
              </FilterItem>
            )
          })}
          <Suggestion
            width='180px'
            placeholder={placeholder}
            options={members}
            selected={selected}
            inputValue={inputValue}
            setSelected={setSelected}
            setInputValue={setInputValue}
          />
        </>
      )
    default:
      return null
  }
}

export default memo(SimpleFiltersSwitcher)
