import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'max-content',
  height: '339px',
  width: '100%',
  padding: '20px',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '10px',
  background: COLORS.white,
  border: `1px solid ${COLORS.gray87}`,

  '.MuiTypography-root': {
    gridColumn: '1 / 3'
  }
}))

export const Title = styled(Typography)(() => ({
  color: COLORS.green,
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '18px',
  marginBottom: '20px'
}))

export const SubTitles = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '13px',
  lineHeight: '16px',
  marginBottom: '7px'
}))

export const StyledDoubleBox = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridColumn: '1 / 3'
}))

export const StyledAddressBox = styled(Box)(() => ({
  gridColumn: '1 / 3'
}))

export const StyledNoteBox = styled(Box)(() => ({
  gridColumn: '1 / 3'
}))

export const ButtonsContainer = styled(Box)(() => ({
  gridColumn: '1 / 3',
  '.MuiButtonBase-root': {
    width: 'min-content',
    marginLeft: '8px'
  },

  'a > .MuiButtonBase-root': {
    marginLeft: '0px'
  }
}))

export const ValueContainer = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const NoteValueContainer = styled(Typography)`
  overflow: hidden;
  textoverflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  word-break: break-word;
  -webkit-box-orient: vertical;
`
