import { createAsyncThunk } from '@reduxjs/toolkit'

import Api from '@services'
import { IClubCreationPayload } from '@store/features/clubs/clubs.types'

export const createClubThunk = createAsyncThunk(
  'club/create',
  async (payload: IClubCreationPayload, { rejectWithValue }) => {
    try {
      const response = await Api.club.createClub(payload)

      return response?.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
  }
)

export const getClubsThunk = createAsyncThunk(
  'clubs/get',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const response = await Api.club.getClubs(url)

      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getClubThunk = createAsyncThunk(
  'club/get',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.club.getClub(id)

      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateClubThunk = createAsyncThunk(
  'club/update',
  async ({ id, data }: { id: string; data: any }, { rejectWithValue }) => {
    try {
      const response = await Api.club.updateClub(id, data)

      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteClubThunk = createAsyncThunk(
  'club/delete',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      await Api.club.deleteClub(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
