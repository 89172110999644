import { createSlice } from '@reduxjs/toolkit'

import { IClubInitial } from '@store/features/clubs/clubs.types'
import { createClubThunk, updateClubThunk, getClubThunk } from '@store/features/clubs/clubs.actions'
import { numberOfMembersOptions, timezoneOptions } from '@constants/fields'

const initialFormState = {
  note: '',
  logo: '',
  phone: '',
  state: '',
  owner: '',
  password: '',
  clubName: '',
  clubNotes: '',
  clubAddress: '',
  email: '',
  timeZone: { name: '', value: '' },
  numberOfMembers: { name: '', value: '' }
}
const contents = [
  { name: 'Info' },
  { name: 'Members' },
  { name: 'Courses' },
  { name: 'Ticket History' }
]

const initialState: IClubInitial = {
  contents,
  initialFormState,
  contentLoader: true,
  currentContent: { name: 'Info' },
  execution: {
    status: '',
    isLoading: false
  }
}

const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {
    clearFilledClubInfo: (state) => {
      state.initialFormState = {
        ...initialFormState,
        timeZone: timezoneOptions[0],
        numberOfMembers: numberOfMembersOptions[0]
      }
      state.currentContent = { name: 'Info' }
    },
    changeInitialFormState: (state, { payload }) => {
      state.initialFormState = {
        ...state.initialFormState,
        ...payload
      }
    },
    comebackExecutionState: (state) => {
      state.execution.isLoading = false
      state.execution.status = ''
    },
    comebackInitialState: (state) => {
      state.initialFormState = initialFormState
      state.execution.isLoading = false
      state.execution.status = ''
    },
    setCurrentContent: (state, { payload }) => {
      state.currentContent = payload
    },
    setDefaultStatus: (state) => {
      state.execution.status = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClubThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(updateClubThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(updateClubThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = 'updated'
      })
      .addCase(updateClubThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
      .addCase(createClubThunk.pending, (state) => {
        state.execution.isLoading = true
      })
      .addCase(createClubThunk.fulfilled, (state) => {
        state.execution.isLoading = false
        state.execution.status = 'created'
      })
      .addCase(createClubThunk.rejected, (state) => {
        state.execution.isLoading = false
      })
  }
})

export const {
  comebackInitialState,
  setCurrentContent,
  comebackExecutionState,
  changeInitialFormState,
  clearFilledClubInfo,
  setDefaultStatus
} = clubSlice.actions

export default clubSlice.reducer
