export enum PAGE_ROUTES_BASE_PRIVATE {
  UPDATE_COURSE_BASE = '/update-course',
  UPDATE_CLUB_BASE = '/club',
  ADD_MEMBER_FOR_CLUB = '/create-member',
  UPLOAD_MEMBERS_FOR_CLUB = '/upload-members'
}

export enum PAGE_ROUTES_PRIVATE {
  EMPTY = '',
  HOME = '/',

  All_CLUBS = '/clubs',
  CREATE_CLUB = '/create-club',
  CLUB = '/club/:id',
  UPDATE_CLUB = '/club/:id',

  CREATE_COURSE = '/create-course',
  OPEN_TICKETS = '/open-tickets',
  TICKET_HISTORY = '/ticket-history',
  UPDATE_COURSE = '/update-course/:id',
  All_COURSES = '/courses',
  ADD_MEMBER = '/create-member/:clubId',
  UPLOAD_MEMBERS = '/upload-members/:clubId',
  ALL_MEMBERS = '/members',
  OPEN_TICKETS_ID = '/open-tickets/:id'
}

export enum PAGE_ROUTES_PUBLIC {
  SIGN_IN = '/auth/sign-in',
  FORGOT_PASSWORD = '/auth/forgot-password',
  RECOVER_PASSWORD = '/auth/recover-password'
}
