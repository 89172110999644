import { useEffect, memo, Suspense } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Header from '@components/Header'
import Content from '@components/Hocs/Content'
import { RouteEnum } from '@appTypes/enums/global'
import { PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages'

import { savePrevPathname } from '@store/features/general/general.slice'
import usePrevious from '@hook/usePrevious'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { profileThunk } from '@store/features/auth/auth.actions'
import { ContentHeader } from './components'
import { ContentLoader } from './styled'

function LayoutPrivate() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const user = useAppSelector((state) => state.auth.user)
  const execution = useAppSelector((state) => state.auth.execution)
  const { disableBackButton, prevPages } = useAppSelector((state) => state.general)
  const prevPathArray = usePrevious<string[]>(prevPages)
  const prevPath = usePrevious<string>(location.pathname)

  // const whichStorage = StorageManager.getString(StorageKeysEnum.REMEMBER_ME) as string
  // const accessToken = StorageManager.getItem(
  //   StorageKeysEnum.ACCESS_TOKEN,
  //   whichStorage === StorageCaseEnum.SESSION ? StorageCaseEnum.SESSION : StorageCaseEnum.LOCAL
  // )

  useEffect(() => {
    if (prevPath && prevPages.length === prevPathArray.length) {
      dispatch(savePrevPathname({ pathname: prevPath }))
    }
  }, [location.pathname])

  useEffect(() => {
    if (user === null) {
      dispatch(profileThunk())
        .unwrap()
        .catch(() => navigate(PAGE_ROUTES_PUBLIC.SIGN_IN))
    }
  }, [user])

  return (
    <>
      <Header type={RouteEnum.PRIVATE} />
      <Content type={RouteEnum.PRIVATE}>
        <Suspense fallback={<ContentLoader />}>
          {execution.isLoading ? (
            <ContentLoader />
          ) : (
            <>
              {!disableBackButton ? <ContentHeader /> : null}
              <Outlet />
            </>
          )}
        </Suspense>
      </Content>
    </>
  )
}

export default memo(LayoutPrivate)
