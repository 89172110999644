import React, { useContext, useState, useEffect, SetStateAction, Dispatch } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AutocompleteRenderInputParams, TextField } from '@mui/material'

import type { IOption } from '@appTypes/model/filters.model'
import type { ISearchMemberBy } from '@appTypes/global'
import { FiltersParamsKeysEnum } from '@appTypes/enums/filters'

import { useAppDispatch } from '@store/hooks'
import { clearMembersList } from '@store/features/member/member.slice'
import { getMemberThunk } from '@store/features/member/member.actions'
import { FiltersContext } from '@contexts/filters'

interface IUseSuggestion {
  options: IOption[]
  selected: IOption | null
  setSelected: Dispatch<SetStateAction<IOption | null>>
}

interface RenderInputProps {
  placeholder: string
  params: AutocompleteRenderInputParams
  setInputValue: Dispatch<SetStateAction<string>>
}

export const useSuggestion = ({ options, selected, setSelected }: IUseSuggestion) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { collectQueryParams } = useContext(FiltersContext)

  const [foundByMemberId, setFoundByMemberId] = useState<ISearchMemberBy | null>(null)

  const renderInput = ({ params, placeholder, setInputValue }: RenderInputProps) => {
    return (
      <TextField
        {...params}
        placeholder={placeholder}
        onChange={(event) => {
          if (foundByMemberId) setFoundByMemberId(null)

          setInputValue(event.target.value)

          if (event.target.value === '') {
            collectQueryParams({ [FiltersParamsKeysEnum.memberId]: '' })
          }
        }}
        inputProps={{
          ...params.inputProps,
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              e.stopPropagation()
            }
          }
        }}
      />
    )
  }

  const handleChange = (_: any, option: string | IOption | null) => {
    if (typeof option === 'string' || selected?.id === option?.id) {
      return
    }

    if (!option) {
      setFoundByMemberId(null)
      dispatch(clearMembersList())
      setSelected(null)

      collectQueryParams({ [FiltersParamsKeysEnum.memberId]: '' })
      return
    }

    if (option?.id) {
      collectQueryParams({ [FiltersParamsKeysEnum.memberId]: option.id })
    }

    setSelected(option)
  }

  const renderOption = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: IOption | null
  ) => {
    return (
      <li {...optionProps} key={option?.id}>
        {option?.name}
      </li>
    )
  }

  useEffect(() => {
    const memberId = searchParams.get(FiltersParamsKeysEnum.memberId)

    if (memberId && !options?.length) {
      dispatch(getMemberThunk({ id: memberId }))
        .unwrap()
        .then((response) => {
          setFoundByMemberId(response)
        })
    }
  }, [])

  useEffect(() => {
    setSelected(foundByMemberId)
  }, [foundByMemberId])

  return { renderOption, handleChange, renderInput }
}
