import { memo, useState } from 'react'

import Box from '@mui/material/Box'

import { deleteClubThunk } from '@store/features/clubs/clubs.actions'
import { IClub } from '@store/features/clubs/clubs.types'
import { setPopUpData } from '@store/features/general/general.slice'
import { useAppDispatch } from '@store/hooks'
import { Button, LinkTo } from '@components/common'
import { PAGE_ROUTES_BASE_PRIVATE } from '@appTypes/enums/pages'
import {
  Section,
  Title,
  SubTitles,
  StyledDoubleBox,
  StyledAddressBox,
  ButtonsContainer,
  ValueContainer
} from './styled'

interface ICourseBox extends IClub {}

const ClubBox = ({ id, name, phone, email, address, note }: ICourseBox) => {
  const dispatch = useAppDispatch()
  const [loader, setLoader] = useState<string | null>(null)

  return (
    <Section>
      <Title>{name}</Title>
      <StyledDoubleBox>
        <Box>
          <SubTitles>Phone Number</SubTitles>
          <ValueContainer>{phone}</ValueContainer>
        </Box>
        <Box>
          <SubTitles>Email</SubTitles>
          <ValueContainer>{email}</ValueContainer>
        </Box>
      </StyledDoubleBox>
      <StyledAddressBox>
        <SubTitles>Club Address</SubTitles>
        <ValueContainer>{address}</ValueContainer>
      </StyledAddressBox>
      <StyledAddressBox>
        <SubTitles>Club Note</SubTitles>
        <ValueContainer>{note}</ValueContainer>
      </StyledAddressBox>
      <ButtonsContainer>
        <LinkTo href={`${PAGE_ROUTES_BASE_PRIVATE.UPDATE_CLUB_BASE}/${id}`}>
          <Button variant='contained' color='primary' type='button'>
            Edit
          </Button>
        </LinkTo>

        <Button
          variant='outlined'
          color='primary'
          type='button'
          isLoading={loader === 'delete'}
          onClick={() => {
            setLoader('delete')
            dispatch(deleteClubThunk({ id }))
              .then(() => {
                dispatch(setPopUpData({ status: true, popUpId: id }))
                setLoader(null)
              })
              .catch(() => {
                setLoader(null)
              })
          }}
        >
          Delete
        </Button>
      </ButtonsContainer>
    </Section>
  )
}

export default memo(ClubBox)
