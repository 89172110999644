import { useEffect, memo, Suspense } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { RouteEnum, StorageKeysEnum, StorageCaseEnum } from '@appTypes/enums/global'
import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'

import { Header } from '@components'
import { useAppSelector } from '@store/hooks'
import Content from '@components/Hocs/Content'
import { ContentLoader } from '@layout/Private/styled'
import StorageManager from '@utils/storage-manager'

function LayoutPublic() {
  const user = useAppSelector((state) => state.auth.user)
  const navigate = useNavigate()

  useEffect(() => {
    const where = StorageManager.getString(StorageKeysEnum.REMEMBER_ME)
    const from = where === 'session' ? StorageCaseEnum.SESSION : StorageCaseEnum.LOCAL

    const access = StorageManager.getString(StorageKeysEnum.ACCESS_TOKEN, from)

    if (access && user !== null && Object.keys(user).length) {
      navigate(PAGE_ROUTES_PRIVATE.HOME)
    }
  }, [user?.id])

  return (
    <>
      <Header type={RouteEnum.PRIVATE} />
      <Content type={RouteEnum.PUBLIC}>
        <Suspense fallback={<ContentLoader />}>
          <Outlet />
        </Suspense>
      </Content>
    </>
  )
}

export default memo(LayoutPublic)
