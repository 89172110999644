/* eslint-disable no-useless-catch */
import axios from 'axios'
import { http } from '@api/RequestApi'
import { API_URL } from '@configs'

export interface IUploadFileProps {
  file: File
  url: string
}

class S3Service {
  static async getUploadUrl(folder: string, extension = 'jpeg', name = '') {
    const { data } = await http.get<any, any>('/aws', { params: { folder, name, extension } })

    return data
  }

  static async uploadFile(url: string, file: File) {
    try {
      const options = {
        headers: { 'Content-Type': 'application/octet-stream' },
        withCredentials: false
      }

      const client = axios.create({
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'json',
        withCredentials: false,
        baseURL: API_URL
      })

      return client.put(url, file, options)
    } catch (error) {
      throw error
    }
  }

  static getCDNImagePath(path: string) {
    return `${process.env.REACT_APP_AMAZON_S3_CDN_URL}/${path}`
  }
}

export default S3Service
