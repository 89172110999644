import { useState, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import type { IFiltersProps, IOption } from '@appTypes/model/filters.model'
import { FiltersParamsKeysEnum } from '@appTypes/enums/filters'

import usePrevious from '@hook/usePrevious'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { suggestMembersByKeyThunk } from '@store/features/member/member.actions'
import { clearMembersList } from '@store/features/member/member.slice'
import { FiltersContext } from '@contexts/filters'
import useDebounceWithCallback from '@hook/useDebounceWithCallback'

const useHandleFilters = () => {
  const dispatch = useAppDispatch()

  const { collectQueryParams } = useContext(FiltersContext)
  const members = useAppSelector((state) => state.member.memberList)
  const [searchParams] = useSearchParams()
  const [selected, setSelected] = useState<IOption | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [searchByKey, setSearchByKey] = useState<string>(
    searchParams.get(FiltersParamsKeysEnum.key) || ''
  )

  const previousSearchByKey = usePrevious(searchByKey)

  const handleSelectChange = (data: IFiltersProps) => {
    for (const key in data) {
      collectQueryParams({ [key]: data[key as FiltersParamsKeysEnum] })
    }
  }

  const getMembersList = async (name: string) => {
    try {
      dispatch(suggestMembersByKeyThunk({ key: name }))
    } catch (error: any) {
      console.log(error)
    }
  }

  useDebounceWithCallback(
    inputValue,
    () => {
      if (!inputValue) {
        dispatch(clearMembersList())

        return
      }

      getMembersList(inputValue)
    },
    300
  )

  useDebounceWithCallback(
    searchByKey,
    () => {
      if (
        (searchByKey && previousSearchByKey !== undefined) ||
        (previousSearchByKey && !searchByKey)
      ) {
        handleSelectChange({ [FiltersParamsKeysEnum.key]: searchByKey || '' })
      }
    },
    300
  )

  return {
    members,
    selected,
    inputValue,
    searchByKey,
    setSearchByKey,
    setSelected,
    setInputValue,
    handleSelectChange
  }
}

export default useHandleFilters
