import { memo, useEffect } from 'react'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'

import { DialogAlert } from '@components'
import { CssBaseline, ThemeProvider } from '@mui/material'

import { BaseAPI } from '@services/ApiServices'

import { store } from './store'
import theme from './theme'
import { router } from './router'

const App = () => {
  useEffect(() => {
    store.subscribe(() => {
      if (store.getState().auth.user) {
        BaseAPI.init(store.getState().auth.user)
      }
    })
  }, [])

  const routing = useRoutes(router())

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DialogAlert />
        {routing}
      </ThemeProvider>
    </Provider>
  )
}

export default memo(App)
