export enum RouteEnum {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export enum StorageKeysEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  REMEMBER_ME = 'remember_me'
}

export enum StorageCaseEnum {
  LOCAL = 'local',
  SESSION = 'session'
}

export enum ValidationKeysEnum {
  LOGIN = 'login',
  FORGOT = 'forgot',
  CREATE_CLUB = 'createClub',
  CREATE_COURSE = 'createCourse',
  CREATE_MEMBER = 'createMember',
  RECOVER_PASSWORD = 'recoverPassword'
}

export enum CourseViewCasesEnum {
  CREATE = 'create',
  UPDATE = 'update'
}

export enum ClubViewCasesEnum {
  CREATE = 'create',
  UPDATE = 'update'
}

export enum DialogAlertKeysEnum {
  filterByDate = 'filterByDate',
  ticketDeclined = 'ticketDeclined',
  courseDeleted = 'courseDeleted',
  memberDeleted = 'memberDeleted',
  ticketCounter = 'ticketCounter'
}
