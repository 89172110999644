import { createAsyncThunk } from '@reduxjs/toolkit'

import Api from '@services'
import type { IViewDataWithClubId, IMember } from '@store/features/member/member.types'
import type { IOption } from '@appTypes/model/filters.model'

export const createMemberThunk = createAsyncThunk(
  'member/create',
  async (payload: IViewDataWithClubId, { rejectWithValue }) => {
    try {
      const { email, password, phone, name, number, clubId } = payload

      await Api.member.createMember({
        email,
        password,
        phone,
        number,
        clubId,
        fullName: name
      })

      return {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getMembersThunk = createAsyncThunk(
  'members/get',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const response = await Api.member.getMembers(url)

      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const suggestMembersByKeyThunk = createAsyncThunk(
  'members/get',
  async (payload: { key: string }, { rejectWithValue }) => {
    try {
      const response = await Api.member.getMembersByKey({ key: payload.key })

      const members: IOption[] = response?.data?.data.map((member: IMember) => ({
        id: member.id,
        name: member.fullName
      }))

      return members || []
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getMemberThunk = createAsyncThunk(
  'member/get',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.member.getMember({ id })

      const member = response?.data?.data

      return {
        name: member.fullName,
        id: member.id
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteMemberThunk = createAsyncThunk(
  'courses/delete',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      await Api.member.deleteMember(id)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
