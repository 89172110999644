export enum FiltersStatusesEnum {
  APPROVED_CANCELED = 'APPROVED_CANCELED',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING'
}

export enum TicketCounterStatuses {
  REJECTED = 'REJECTED',
  NO_OFFER = 'NO_OFFER',
  PENDING = 'PENDING'
}

export enum FiltersParamsKeysEnum {
  key = 'key',
  memberId = 'memberId',
  orderBy = 'orderBy',
  sortBy = 'sortBy',
  status = 'status',
  statuses = 'statuses',
  limit = 'limit',
  offset = 'offset',
  isFinished = 'isFinished'
}

export enum FiltersOrderValuesEnum {
  date = 'date',
  createdAt = 'createdAt'
}

export enum FiltersSortValuesEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum FiltersPropsTitleEnum {
  OpenTickets = 'Open Tickets',
  TicketHistory = 'Ticket History',
  AllCourses = 'All Courses',
  AllClubs = 'All Clubs',
  AllMembers = 'All Members'
}
