import { memo, useState } from 'react'

import type { IMember } from '@store/features/member/member.types'

import Box from '@mui/material/Box'
import { setPopUpData } from '@store/features/general/general.slice'
import { deleteMemberThunk } from '@store/features/member/member.actions'
import { useAppDispatch } from '@store/hooks'
import { Button } from '@components/common'
import {
  Section,
  Title,
  SubTitles,
  StyledDoubleBox,
  ButtonsContainer,
  ValueContainer
} from './styled'

interface IMembersBox extends IMember {}

const MemberBox = ({ id, email, fullName, number, phone }: IMembersBox) => {
  const dispatch = useAppDispatch()
  const [loader, setLoader] = useState<string | null>(null)

  return (
    <Section>
      <Title>{fullName}</Title>
      <StyledDoubleBox>
        <Box>
          <SubTitles>Phone Number</SubTitles>
          <ValueContainer>{phone}</ValueContainer>
        </Box>
        <Box>
          <SubTitles>Email</SubTitles>
          <ValueContainer>{email}</ValueContainer>
        </Box>
      </StyledDoubleBox>
      <StyledDoubleBox>
        <Box>
          <SubTitles>Member ID</SubTitles>
          <ValueContainer>{number}</ValueContainer>
        </Box>
      </StyledDoubleBox>
      <ButtonsContainer>
        <Button
          variant='outlined'
          color='primary'
          type='button'
          isLoading={loader === 'delete'}
          onClick={() => {
            setLoader('delete')
            dispatch(deleteMemberThunk({ id }))
              .then(() => {
                dispatch(setPopUpData({ status: true, popUpId: id }))
                setLoader(null)
              })
              .catch(() => {
                setLoader(null)
              })
          }}
        >
          Delete
        </Button>
      </ButtonsContainer>
    </Section>
  )
}

export default memo(MemberBox)
