import { configureStore } from '@reduxjs/toolkit'

import authReducer from '@store/features/auth/auth.slice'
import courseReducer from '@store/features/course/course.slice'
import memberReducer from '@store/features/member/member.slice'
import generalReducer from '@store/features/general/general.slice'
import ticketReducer from '@store/features/ticket/ticket.slice'
import clubReducer from '@store/features/clubs/clubs.slice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    auth: authReducer,
    course: courseReducer,
    member: memberReducer,
    general: generalReducer,
    ticket: ticketReducer,
    club: clubReducer
  }
})

// store.subscribe(() => {
//   const { order } = store.getState()
//   saveOrderState(order)
// })
