import { memo } from 'react'

import { PAGE_ROUTES_BASE_PRIVATE, PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { Button, LinkTo } from '@components/common'
import { useParams } from 'react-router-dom'

interface ISimpleButtonsSwitcher {
  section: string
}

const SimpleButtonsSwitcherWithoutUrl = ({ section }: ISimpleButtonsSwitcher) => {
  const params = useParams()

  const memberUrl = params?.id
    ? `${PAGE_ROUTES_BASE_PRIVATE.ADD_MEMBER_FOR_CLUB}/${params?.id}`
    : PAGE_ROUTES_PRIVATE.HOME

  switch (section) {
    case 'courses':
      return (
        <LinkTo href={PAGE_ROUTES_PRIVATE.CREATE_COURSE}>
          <Button variant='contained' color='primary' type='button'>
            Create a Course
          </Button>
        </LinkTo>
      )
    case 'members':
      return (
        <LinkTo href={memberUrl}>
          <Button variant='contained' color='primary' type='button'>
            Add a Member
          </Button>
        </LinkTo>
      )
    default:
      return null
  }
}

export default memo(SimpleButtonsSwitcherWithoutUrl)
