export const timezoneOptions = [
  { name: 'Eastern Standard Time', value: 'America/New_York EST_UTS-5' }, // Same Florida, custom added EDT_UTC-4
  { name: 'Eastern Daylight Time', value: 'America/New_York2 EDT_UTC-4' }
]

export const numberOfMembersOptions = [
  { name: 'Less then 100', value: 'Less then 100' },
  { name: '100-250', value: '100-250' },
  { name: '250-400', value: '250-400' },
  { name: '400 and more', value: '400 and more' }
]
