import { StorageCaseEnum, StorageKeysEnum } from '@appTypes/enums/global'

class StorageManager {
  static setItem = (
    key: StorageKeysEnum,
    data: string,
    storageType?: StorageCaseEnum.LOCAL | StorageCaseEnum.SESSION
  ) => {
    try {
      const serializedState = JSON.stringify(data)
      const storage =
        storageType === StorageCaseEnum.LOCAL || storageType === undefined
          ? localStorage
          : sessionStorage

      storage.setItem(key, serializedState)
    } catch {
      // ignore write errors
    }
  }

  static setString = (
    key: StorageKeysEnum,
    value: string,
    storageType?: StorageCaseEnum.LOCAL | StorageCaseEnum.SESSION
  ) => {
    const storage =
      storageType === StorageCaseEnum.LOCAL || storageType === undefined
        ? localStorage
        : sessionStorage

    storage.setItem(key, value)
  }

  static getString = (
    key: StorageKeysEnum,
    storageType?: StorageCaseEnum.LOCAL | StorageCaseEnum.SESSION
  ) => {
    const storage =
      storageType === StorageCaseEnum.LOCAL || storageType === undefined
        ? localStorage
        : sessionStorage

    return storage.getItem(key)
  }

  static getItem = (
    key: StorageKeysEnum,
    storageType?: StorageCaseEnum.LOCAL | StorageCaseEnum.SESSION
  ) => {
    try {
      const storage =
        storageType === StorageCaseEnum.LOCAL || storageType === undefined
          ? localStorage
          : sessionStorage

      const serializedState = storage.getItem(key)

      if (serializedState === null) {
        return
      }

      // eslint-disable-next-line consistent-return
      return JSON.parse(serializedState)
    } catch (err) {
      // ignore write errors
    }
  }

  static removeItem = (
    key: StorageKeysEnum,
    storageType?: StorageCaseEnum.LOCAL | StorageCaseEnum.SESSION
  ) => {
    const storage =
      storageType === StorageCaseEnum.LOCAL || storageType === undefined
        ? localStorage
        : sessionStorage

    storage.removeItem(key)
  }

  static clearItems = (storageType?: StorageCaseEnum.LOCAL | StorageCaseEnum.SESSION) => {
    const storage = storageType === StorageCaseEnum.LOCAL ? localStorage : sessionStorage

    storage.clear()
  }
}

export default StorageManager
