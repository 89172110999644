import { lazy } from 'react'

import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@appTypes/enums/pages'
import ErrorBoundary from '@components/ErrorBoundary'
import { ClubViewCasesEnum, RouteEnum } from '@appTypes/enums/global'
import LayoutPrivate from '@layout/Private'
import LayoutPublic from '@layout/Public'
import { NotFound } from '@components'

const Home = lazy(() => import('../pages/Home'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const RecoverPassword = lazy(() => import('../pages/RecoverPassword'))
const SignIn = lazy(() => import('../pages/SignIn'))
const Club = lazy(() => import('../pages/Club'))
const Clubs = lazy(() => import('../pages/Clubs'))
const MutateClub = lazy(() => import('../pages/MutateClub'))

const CreateMember = lazy(() => import('../pages/CreateMember'))
const UploadMembers = lazy(() => import('../pages/UploadMembers'))

// const Courses = lazy(() => import('../pages/Courses'))
// const Members = lazy(() => import('../pages/Members'))
// const MutateCourse = lazy(() => import('../pages/MutateCourse'))
// const OpenTickets = lazy(() => import('../pages/OpenTickets'))
// const TicketApprove = lazy(() => import('../pages/TicketApprove'))
// const TicketsHistory = lazy(() => import('../pages/TicketsHistory'))

const privateRoutes = [
  {
    path: PAGE_ROUTES_PRIVATE.HOME,
    type: RouteEnum.PRIVATE,
    element: <Home />
  },
  {
    path: PAGE_ROUTES_PRIVATE.All_CLUBS,
    type: RouteEnum.PRIVATE,
    element: <Clubs />
  },
  {
    path: PAGE_ROUTES_PRIVATE.CREATE_CLUB,
    type: RouteEnum.PRIVATE,
    element: <MutateClub page={ClubViewCasesEnum.CREATE} />
  },
  {
    path: PAGE_ROUTES_PRIVATE.CLUB,
    type: RouteEnum.PRIVATE,
    element: <Club />
  },
  {
    path: PAGE_ROUTES_PRIVATE.ADD_MEMBER,
    type: RouteEnum.PRIVATE,
    element: <CreateMember />
  },
  {
    path: PAGE_ROUTES_PRIVATE.UPLOAD_MEMBERS,
    type: RouteEnum.PRIVATE,
    element: <UploadMembers />
  },

  // {
  //   path: PAGE_ROUTES_PRIVATE.UPDATE_CLUB,
  //   type: RouteEnum.PRIVATE,
  //   element: <MutateClub />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.CREATE_CLUB,
  //   type: RouteEnum.PRIVATE,
  //   element: <Clubs />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.OPEN_TICKETS,
  //   type: RouteEnum.PRIVATE,
  //   element: <OpenTickets />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.OPEN_TICKETS_ID,
  //   type: RouteEnum.PRIVATE,
  //   element: <TicketApprove />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.TICKET_HISTORY,
  //   type: RouteEnum.PRIVATE,
  //   element: <TicketsHistory />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.All_COURSES,
  //   type: RouteEnum.PRIVATE,
  //   element: <Courses />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.CREATE_COURSE,
  //   type: RouteEnum.PRIVATE,
  //   element: <MutateCourse />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.UPDATE_COURSE,
  //   type: RouteEnum.PRIVATE,
  //   element: <MutateCourse />
  // },
  // {
  //   path: PAGE_ROUTES_PRIVATE.ALL_MEMBERS,
  //   type: RouteEnum.PRIVATE,
  //   element: <Members />
  // },
  {
    path: '*',
    element: <NotFound />
  }
]

const publicRoutes = [
  {
    path: PAGE_ROUTES_PUBLIC.SIGN_IN,
    type: RouteEnum.PUBLIC,
    element: <SignIn />
  },
  {
    path: PAGE_ROUTES_PUBLIC.FORGOT_PASSWORD,
    type: RouteEnum.PUBLIC,
    element: <ForgotPassword />
  },
  {
    path: PAGE_ROUTES_PUBLIC.RECOVER_PASSWORD,
    type: RouteEnum.PUBLIC,
    element: <RecoverPassword />
  },
  {
    path: '*',
    element: <NotFound />
  }
]

export const router = () => {
  return [
    {
      element: (
        <ErrorBoundary>
          <LayoutPrivate />
        </ErrorBoundary>
      ),
      children: privateRoutes
    },
    {
      element: (
        <ErrorBoundary>
          <LayoutPublic />
        </ErrorBoundary>
      ),
      children: publicRoutes
    }
  ]
}
