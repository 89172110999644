import { ThemeOptions } from '@mui/material/styles'
import { FontFamilyNames } from '@appTypes/fonts'
import { COLORS } from '@collections/colors'

export const typography: ThemeOptions['typography'] = (/* theme */) => ({
  fontFamily: FontFamilyNames.Inter,
  h1: {
    fontFamily: FontFamilyNames.InterExtraBold,
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '58px',
    letterSpacing: '-0.02em'
  },
  h2: {},
  h3: {},
  h4: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
    color: COLORS.raisinBlack
  },
  h5: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '140%',
    letterSpacing: '-0.01em'
  },
  h6: {}
})
