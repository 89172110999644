import { memo, ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { StyledInput } from '../../styled'

interface IInput {
  name: string
  placeholder: string
}

const containsOnlyNumbers = (str: string) => {
  return /^\d+$/.test(str)
}

const NumberInput = ({ name, placeholder }: IInput) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <StyledInput
          ref={ref}
          name={name}
          value={value}
          error={!!errors[name]}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const numeric = containsOnlyNumbers(e.target.value)

            if (numeric || e.target.value === '') {
              onChange(e)
            }
          }}
        />
      )}
    />
  )
}

export default memo(NumberInput)
