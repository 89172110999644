import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LoaderWrapper = styled(Box)<{ textarea?: string }>(() => ({
  height: '25px',
  gridColumn: 1 / 4,

  span: {
    position: 'absolute',
    left: '50%'
  }
}))
