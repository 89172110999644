import { useState, memo, forwardRef, Ref } from 'react'
import { useFormContext } from 'react-hook-form'

import { ISelectOption } from '@appTypes/global'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { FormControl, InputLabel, FormHelperText } from '@mui/material'
import { DragDrop } from '@components'

import FormSelect from '../FormSelect'
import { StyledInput } from '../../styled'
import { NumberInput } from '../..'

interface IFormInputWrapper {
  key: string
  name: string
  type: string
  label: string
  placeholder: string
  options?: ISelectOption[]
}

const FormInputWrapper = (
  { label, type, options, ...rest }: IFormInputWrapper,
  ref: Ref<unknown>
) => {
  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext()

  const [isEyeOpen, setEyeOpen] = useState(false)
  const isPassword = type === 'password'
  const textarea = type === 'textarea'

  const errorMessage =
    Object.keys(errors)?.length && errors[rest?.name] ? (errors[rest?.name]?.message as string) : ''

  const handleRender = () => {
    switch (type) {
      case 'number':
        return <NumberInput {...rest} />
      case 'file':
        return <DragDrop existingPath={watch(rest.name)} name={rest.name} />
      case 'select':
        if (!options?.length) return null

        return (
          <FormSelect
            title={label}
            options={options}
            selectedOption={watch(rest.name)}
            setState={(option: { name: string; value: string | number }) => {
              setValue(rest.name, option)
            }}
          />
        )

      case 'password':
      case 'textarea':
      default:
        return (
          <StyledInput
            ref={ref}
            rows={3}
            value={watch(rest.name)}
            type={isPassword ? (isEyeOpen ? 'text' : type) : type}
            multiline={textarea}
            error={!!errors[rest.name]}
            textarea={textarea ? 'is' : ''}
            endAdornment={
              isPassword ? (
                !isEyeOpen ? (
                  <VisibilityOff onClick={() => setEyeOpen(!isEyeOpen)} />
                ) : (
                  <VisibilityIcon onClick={() => setEyeOpen(!isEyeOpen)} />
                )
              ) : null
            }
            {...rest}
          />
        )
    }
  }

  return (
    <FormControl variant='standard'>
      <InputLabel shrink htmlFor={type} error={!!errors[rest.name]}>
        {label}
      </InputLabel>
      {handleRender()}
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export default memo(forwardRef(FormInputWrapper))
