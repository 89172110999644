const MuiSelect = {
  styleOverrides: {
    select: {
      padding: '5px 12px',
      fontSize: '13px',
      borderRadius: '10px'
    },
    root: {
      border: 'none',

      fieldset: {
        border: 0
      }
    }
  }
}

export default MuiSelect
