import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(() => ({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  minHeight: '354px',
  borderRadius: '10px',
  background: COLORS.white,
  border: `1px solid ${COLORS.gray87}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

export const Body = styled(Box)<{ page: string }>(({ page }) => ({
  padding: page === 'TicketsHistory' ? '20px' : '35px 20px 20px 20px',
  width: '100%',
  height: '100%',
  display: 'grid',
  gridGap: '20px',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '94px',

  '.MuiBox-root': {
    '.MuiTypography-h5': {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 700,
      color: COLORS.raisinBlack,
      marginBottom: '10px'
    },
    '.MuiTypography-body1': {
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '140%'
    },
    ':last-child': {
      gridColumn: page === 'TicketsHistory' ? '1/3' : 'unset'
    }
  }
}))

export const Footer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  padding: '20px 0',
  maxHeight: '81px',
  height: '81px',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: `1px solid ${COLORS.gray87}`,

  '.MuiButtonBase-root': {
    width: '104px',
    height: '40px',
    marginLeft: '10px',

    ':nth-ot-type(2)': {
      marginLeft: '0'
    }
  }
}))

export const StyledStatusTypography = styled(Typography)<{ color: string }>(({ color }) => ({
  color
}))

export const MembersWrapper = styled(Box)(() => ({
  '>.MuiTypography-body1': {
    whiteSpace: 'nowrap',
    width: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export const CoursesTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
  -webkit-box-orient: vertical;
`

export const InvalidText = styled(Typography)`
  position: absolute;
  top: -20px;
  left: 20px;
`

export const MemberId = styled('span')`
  display: block;
  color: #d7d7d7;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  cursor: pointer;
`

export const StyledIcon = styled('img')(() => ({
  position: 'absolute',
  top: '21px',
  right: '21px',
  width: '12px',
  height: '12px',
  cursor: 'pointer'
}))
