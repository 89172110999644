import { memo } from 'react'

import { Box } from '@mui/system'
import CloseSvg from '@svgs/close.svg'
import { formatDate } from '@utils/time'
import Typography from '@mui/material/Typography'
import { useSearchParams } from 'react-router-dom'

import { copyText } from '@utils/copyValue'
import type { ITicket } from '@appTypes/global'
import { setPopUpData } from '@store/features/general/general.slice'
import { FiltersStatusesEnum, TicketCounterStatuses } from '@appTypes/enums/filters'
import { useAppDispatch } from '@store/hooks'
import { fireFinishTicket } from '@store/features/ticket/ticket.actions'
import { COLORS } from '@collections/colors'
import { DotsWrapper } from '@components/common'
import TicketBoxButtons from './components/TicketBoxButtons'
import {
  Section,
  Body,
  Footer,
  MembersWrapper,
  CoursesTypography,
  InvalidText,
  MemberId,
  StyledIcon
} from './styled'

interface ITicketBox extends ITicket {
  page: 'OpenTickets' | 'TicketsHistory'
}

const TicketBox = ({
  id,
  date,
  page,
  guests,
  status,
  comment,
  offerStatus,
  ticketMembers,
  ticketCourses,
  ticketCourse,
  availability,
  createdAt,
  isFinished
}: ITicketBox) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const invalid = !ticketMembers.length || !ticketCourses.length
  const requestedDate = createdAt as unknown as string

  const isFinishedItem = () => {
    if (page !== 'OpenTickets' || searchParams.get('isFinished') === 'true') {
      return null
    }

    return (
      <StyledIcon
        src={CloseSvg}
        alt='close icon'
        onClick={() => {
          dispatch(fireFinishTicket({ id }))
            .then(() => {
              dispatch(setPopUpData({ status: true, popUpId: id }))
            })
            .catch(console.log)
        }}
      />
    )
  }

  const renderTicketCourses = () => {
    if (page === 'TicketsHistory' && status === FiltersStatusesEnum.APPROVED) {
      return <CoursesTypography>{ticketCourse?.course?.name}</CoursesTypography>
    }

    if (page === 'OpenTickets' && offerStatus === TicketCounterStatuses.PENDING) {
      return <CoursesTypography>{ticketCourse?.course?.name}</CoursesTypography>
    }

    return ticketCourses?.map((item) => {
      return <CoursesTypography key={`${item.id}`}>{item.course.name}</CoursesTypography>
    })
  }

  const renderTicketMembers = () => {
    return ticketMembers.map((item) => {
      return (
        <Typography key={item.id + id} component='p'>
          {item.member.fullName}
          <Typography component='span' sx={{ color: COLORS.green }}>
            {' '}
            (member)
          </Typography>
          <MemberId onClick={() => copyText(item.member.number)}>ID: {item.member.number}</MemberId>
        </Typography>
      )
    })
  }

  const courses = () => {
    return (
      <Box>
        <Typography component='h5' variant='h5'>
          Courses
        </Typography>
        {renderTicketCourses()}
      </Box>
    )
  }

  const members = () => {
    return (
      <Box>
        <Typography component='h5' variant='h5'>
          Members
        </Typography>
        {ticketMembers?.length ? (
          <MembersWrapper>
            {renderTicketMembers()}
            {guests.map((item) => {
              return (
                <Typography key={item.id} component='p'>
                  {item.fullName}

                  <Typography component='span' sx={{ color: COLORS.gray }}>
                    {' '}
                    (guest)
                  </Typography>
                </Typography>
              )
            })}
          </MembersWrapper>
        ) : null}
      </Box>
    )
  }

  const requestedAtRender = () => {
    return (
      <Box>
        <Typography component='h5' variant='h5'>
          Requested At
        </Typography>
        <Typography component='p'>
          {offerStatus === TicketCounterStatuses.PENDING
            ? formatDate({ date: requestedDate, status, availability, offerStatus, one: true })
            : formatDate({ date: requestedDate, status, availability, one: true })}
        </Typography>
      </Box>
    )
  }

  return (
    <Section>
      {isFinishedItem()}
      <Body page={page}>
        <Box>
          <Typography component='h5' variant='h5'>
            Date and Time
          </Typography>
          <Typography component='p'>
            {offerStatus === TicketCounterStatuses.PENDING
              ? formatDate({ date, status, availability, offerStatus })
              : formatDate({ date, status, availability })}
          </Typography>
        </Box>
        {requestedAtRender()}
        {page === 'TicketsHistory' ? (
          <>
            {members()}
            {courses()}
          </>
        ) : (
          <>
            {courses()}
            {members()}
          </>
        )}
        <Box>
          <Typography component='h5' variant='h5'>
            Comments
          </Typography>
          <DotsWrapper>
            <Typography component='p'>{comment}</Typography>
          </DotsWrapper>
        </Box>
      </Body>
      <Footer>
        {invalid ? <InvalidText>The ticket is missing information.</InvalidText> : null}
        <TicketBoxButtons
          id={id}
          page={page}
          date={date}
          status={status}
          isFinished={isFinished}
          offerStatus={offerStatus}
          availability={availability}
          coursesLength={ticketCourses.length}
          membersLength={ticketMembers.length}
        />
      </Footer>
    </Section>
  )
}

export default memo(TicketBox)
