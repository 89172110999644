import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Image } from '@components/common'

export const ExistingImageContainer = styled(Box)(() => ({}))

export const Wrapper = styled(Box)<{ width?: string }>(({ width = '100' }) => ({
  position: 'relative',
  width: `${width}px`,
  margin: '0 auto'
}))

export const StyledLogo = styled(Image)(() => ({
  width: '100%',
  border: '1px dashed #D5D5D5',
  borderRadius: '10px',
  padding: '5px'
}))

export const StyledClose = styled(Image)(() => ({
  position: 'absolute',
  right: '-15px',
  top: '-15px',
  cursor: 'pointer'
}))

export const FileUploadWrapper = styled(Box)(() => ({
  position: 'relative',
  '#arrow-item': {
    position: 'absolute',
    top: '30px',
    left: '48%',
    right: '48%'
  },

  '> label > div': {
    marginTop: '20px',
    fontWeight: 600,
    fontSize: '13px !important',
    lineHeight: '140%',
    textDecoration: 'unset !important',

    '> span': {
      color: 'black',
      textDecoration: 'unset !important',
      fontWeight: 600,
      fontSize: '13px !important',
      lineHeight: '140%',
      '> span': {
        textDecoration: 'unset !important'
      }
    }
  }
}))
