import { AxiosResponse } from 'axios'
import { http } from '@api/RequestApi'

import { IClub, IClubResponse } from '@store/features/clubs/clubs.types'
import { BaseAPI } from './ApiServices'

class ClubService extends BaseAPI {
  private prefix = '/clubs'

  public async getClubs(url: string) {
    BaseAPI.isUserExist()

    const response = await http.get<any, AxiosResponse<IClubResponse<IClub[]>>>(
      `${this.prefix}?${url}`
    )

    return response
  }

  public async getClub(id: string) {
    const response = await http.get<any, AxiosResponse<IClubResponse<IClub>>>(
      `${this.prefix}/${id}/info`
    )

    return response
  }

  public async createClub(data: any) {
    const response = await http.post<any, AxiosResponse<void>>(`${this.prefix}`, data)

    return response
  }

  public async updateClub(id: string, data: any) {
    const response = await http.put<any, AxiosResponse<IClubResponse<IClub>>>(
      `${this.prefix}/${id}`,
      data
    )

    return response
  }

  public async deleteClub(id: string) {
    return http.delete<void, AxiosResponse<string>>(`${this.prefix}/${id}`)
  }
}

export default ClubService
