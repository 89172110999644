import { memo } from 'react'

import { FiltersStatusesEnum } from '@appTypes/enums/filters'

import { TicketOfferStatusType } from '@store/features/ticket/ticket.types'
import { ticketButtonsHook } from './ticketButtonsHook'
import { StyledStatusTypography } from '../../styled'

interface ITicketBoxButtons {
  page: 'OpenTickets' | 'TicketsHistory'
  offerStatus: TicketOfferStatusType
  id: string
  date: string
  isFinished: boolean
  availability: number
  coursesLength: number
  membersLength: number
  status: FiltersStatusesEnum
}

const TicketBoxButtons = ({ id, status, isFinished }: ITicketBoxButtons) => {
  const { switchColor, switchTitle } = ticketButtonsHook({
    id,
    status
  })

  const switchButtonsVia = () => {
    if (isFinished && status === FiltersStatusesEnum.PENDING) {
      return <div>Closed</div>
    }

    if (
      status === FiltersStatusesEnum.APPROVED ||
      status === FiltersStatusesEnum.DECLINED ||
      status === FiltersStatusesEnum.APPROVED_CANCELED ||
      status === FiltersStatusesEnum.CANCELED // only for Admin
    ) {
      return <StyledStatusTypography color={switchColor()}>{switchTitle()}</StyledStatusTypography>
    }

    return null
  }

  return switchButtonsVia()
}

export default memo(TicketBoxButtons)
