import { useState } from 'react'

import { FileUploader } from 'react-drag-drop-files'
import { useFormContext } from 'react-hook-form'

import CloseSvg from '@svgs/close.svg'
import S3Service from '@services/S3Service'
import ArrowUp from '@svgs/arrow-up.svg'
import { CircularLoader } from '@components/common'
import {
  ExistingImageContainer,
  Wrapper,
  StyledLogo,
  StyledClose,
  FileUploadWrapper
} from './styled'

const fileTypes = ['jpg', 'png']

const DragDrop = ({ existingPath, name }: { existingPath: string; name: string }) => {
  const { setValue, clearErrors } = useFormContext()
  const [isLoading, setLoading] = useState(false)

  const handleUpload = async (file: any) => {
    try {
      if (!file) {
        return
      }
      setLoading(true)

      const response = await S3Service.getUploadUrl('images', 'png')

      const putUrl = response?.data?.putUrl || ''
      const path = response?.data?.path || ''

      await S3Service.uploadFile(putUrl, file)

      if (path) {
        setValue(name, path)
        clearErrors('logo')
      }

      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  if (isLoading) {
    return (
      <ExistingImageContainer>
        <Wrapper width='70'>
          <CircularLoader width={50} height={50} />
        </Wrapper>
      </ExistingImageContainer>
    )
  }

  if (existingPath) {
    return (
      <ExistingImageContainer>
        <Wrapper width='300'>
          <StyledClose
            src={CloseSvg}
            alt='close'
            // @ts-ignore
            onClick={() => {
              setValue('logo', '')
            }}
          />
          <StyledLogo src={S3Service.getCDNImagePath(existingPath)} alt='Logo' />
        </Wrapper>
      </ExistingImageContainer>
    )
  }

  return (
    <FileUploadWrapper>
      <img src={ArrowUp} id='arrow-item' alt='close' />
      <FileUploader
        name='logo'
        types={fileTypes}
        label='Upload a Logo'
        handleChange={handleUpload}
        classes='drop_area drop_zone'
      />
    </FileUploadWrapper>
  )
}

export default DragDrop
