import { styled } from '@mui/material/styles'
import { Select as MuiSelect } from '@mui/material'

export const StyledMuiSelect = styled(MuiSelect)(() => ({
  width: '100%',
  height: '51px !important',
  textAlign: 'left',
  marginTop: '0 !important',

  '&:after': { content: 'none' },
  '&:before': { content: 'none' },

  '.MuiSelect-select': {
    width: '100%',
    height: '51px !important',
    padding: '13px 20px !important',
    border: 'none'
  },
  svg: {
    right: '20px'
  },
  fieldset: {
    display: 'none',
    legend: {
      visibility: 'unset'
    }
  }
}))
