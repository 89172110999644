import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

import { deleteLastPathname } from '@store/features/general/general.slice'
import { useAppSelector } from '@store/hooks'
import { StyledBox } from './styled'

const ContentHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const owner = useAppSelector((state) => state.auth?.user)
  const { prevPages } = useAppSelector((state) => state.general)

  const handleGoBack = () => {
    if (!prevPages.length) {
      navigate('/')
      return false
    }

    const path: string = prevPages.at(-1) as string

    dispatch(deleteLastPathname())
    navigate(path)
  }

  if (location.pathname === '/') {
    return (
      <Typography component='h5' variant='h5'>
        Welcome, {owner?.username || owner?.email} !
      </Typography>
    )
  }

  return (
    <StyledBox onClick={handleGoBack}>
      <NavigateBeforeIcon />
      Back
    </StyledBox>
  )
}

export default ContentHeader
