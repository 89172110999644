import { ReactNode, memo } from 'react'
import { useSearchParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import TicketLoaderWrapper from '@components/TicketLoaderWrapper'
import { Content } from './styled'

interface ITicketsMissingWrapper {
  children: ReactNode
  loader: boolean
  dataLength: number
}

const TicketsMissingWrapper = ({ loader, dataLength, children }: ITicketsMissingWrapper) => {
  const [searchParams] = useSearchParams()

  return (
    <TicketLoaderWrapper loader={loader}>
      <Content empty={!dataLength ? 'empty' : ''}>
        {!dataLength ? (
          <Typography component='p' variant='body2'>
            {searchParams.get('key') ? 'This member has no tickets.' : 'There are no tickets yet.'}
          </Typography>
        ) : (
          children
        )}
      </Content>
    </TicketLoaderWrapper>
  )
}

export default memo(TicketsMissingWrapper)
