import { forwardRef, ReactElement } from 'react'

import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setPopUpData } from '@store/features/general/general.slice'

import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import DialogAlertClubDeletedVia from './components/DialogAlertClubDeletedVia'
import DialogAlertCourseDeletedVia from './components/DialogAlertCourseDeletedVia'
import DialogAlertTicketDeclineVia from './components/DialogAlertTicketDeclineVia'
import DialogAlertMemberDeletedVia from './components/DialogAlertMemberDeletedVia'

import { StyledDialog } from './styled'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const components = {
  ticketDeclined: <DialogAlertTicketDeclineVia />,
  courseDeleted: <DialogAlertCourseDeletedVia />,
  memberDeleted: <DialogAlertMemberDeletedVia />,
  clubDeleted: <DialogAlertClubDeletedVia />
}

const DialogAlert = () => {
  const popUpType = useAppSelector((state) => state.general.popUp.popUpType)

  const dispatch = useAppDispatch()

  return (
    <StyledDialog
      open={!!popUpType}
      keepMounted
      onClose={() => dispatch(setPopUpData({ popUpType: null, popUpId: null }))}
      TransitionComponent={Transition}
      aria-describedby='alert-dialog-slide-description'
    >
      {components[popUpType as keyof typeof components]}
    </StyledDialog>
  )
}

export default DialogAlert
