import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { COLORS } from '@collections/colors'

export const Section = styled(Box)(() => ({
  marginTop: '38px',

  '.MuiTypography-root.MuiTypography-h4': {
    marginBottom: '20px'
  }
}))

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',

  '> .MuiBox-root': {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center'
  }
}))

export const FilterItem = styled(Box)<{ active: 'active' | '' }>(({ active }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  padding: '9px 16px',
  height: '36px',
  border: active ? `1px solid ${COLORS.black}` : `1px solid ${COLORS.borderGray}`,
  borderRadius: '10px',

  '.MuiTypography-body1': {
    color: active ? COLORS.black : COLORS.blackAlfa
  }
}))
