import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import { Button, LinkTo } from '@components/common'
import { useLocation } from 'react-router-dom'
import FilterButtonsWrapper from '../FilterButtonsWrapper'

interface ISimpleButtonsSwitcher {
  total?: number
}

const SimpleButtonsSwitcher = ({ total }: ISimpleButtonsSwitcher) => {
  const location = useLocation()

  switch (location.pathname) {
    case PAGE_ROUTES_PRIVATE.TICKET_HISTORY:
      return <FilterButtonsWrapper total={total} />
    case PAGE_ROUTES_PRIVATE.All_CLUBS:
      return (
        <LinkTo href={PAGE_ROUTES_PRIVATE.CREATE_CLUB}>
          <Button variant='contained' color='primary' type='button'>
            Add a Club
          </Button>
        </LinkTo>
      )
    case PAGE_ROUTES_PRIVATE.All_COURSES:
      return (
        <LinkTo href={PAGE_ROUTES_PRIVATE.CREATE_COURSE}>
          <Button variant='contained' color='primary' type='button'>
            Create a Course
          </Button>
        </LinkTo>
      )
    case PAGE_ROUTES_PRIVATE.ALL_MEMBERS:
      return (
        <LinkTo href={PAGE_ROUTES_PRIVATE.ADD_MEMBER}>
          <Button variant='contained' color='primary' type='button'>
            Add a Member
          </Button>
        </LinkTo>
      )
    default:
      return null
  }
}

export default SimpleButtonsSwitcher
