import { memo } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { PAGE_ROUTES_PRIVATE } from '@appTypes/enums/pages'
import {
  FiltersParamsKeysEnum,
  FiltersOrderValuesEnum,
  FiltersPropsTitleEnum
} from '@appTypes/enums/filters'

import useHandleFilters from './useHandleFilters'
import { SimpleFiltersSwitcher } from './components'
import { Section, Wrapper } from './styled'
import SimpleButtonsSwitcher from './components/SimpleButtonsSwitcher'
import SimpleButtonsSwitcherWithoutUrl from './components/SimpleButtonsSwitcherWithoutUrl'

interface IFilters {
  section?: string
  total?: number
  page: PAGE_ROUTES_PRIVATE
  placeholder: string
  title: FiltersPropsTitleEnum
}

export const filterItems = [
  {
    id: 1,
    key: FiltersParamsKeysEnum.orderBy,
    value: FiltersOrderValuesEnum.createdAt,
    title: 'Sorted by Date'
  },
  {
    id: 2,
    key: FiltersParamsKeysEnum.orderBy,
    value: FiltersOrderValuesEnum.date,
    title: 'Sorted by Tee Time'
  }
]

const Filters = ({ page, total, title, section, placeholder }: IFilters) => {
  const {
    members,
    selected,
    inputValue,
    searchByKey,
    setSelected,
    setInputValue,
    setSearchByKey,
    handleSelectChange
  } = useHandleFilters()

  return (
    <Section>
      <Typography component='h4' variant='h4'>
        {title}
      </Typography>
      <Wrapper>
        <Box>
          <SimpleFiltersSwitcher
            page={page}
            members={members}
            selected={selected}
            inputValue={inputValue}
            searchByKey={searchByKey}
            placeholder={placeholder}
            setInputValue={setInputValue}
            setSelected={setSelected}
            setSearchByKey={setSearchByKey}
            handleSelectChange={handleSelectChange}
          />
        </Box>
        {section ? (
          <SimpleButtonsSwitcherWithoutUrl section={section} />
        ) : (
          <SimpleButtonsSwitcher total={total} />
        )}
      </Wrapper>
    </Section>
  )
}

export default memo(Filters)
