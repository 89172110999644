import { FiltersStatusesEnum } from '@appTypes/enums/filters'
import { createAsyncThunk } from '@reduxjs/toolkit'

import Api from '@services'

interface UpdateTicketPayload {
  ticketId: string
  payload: {
    date?: string
    ticketCourseId?: string
    status: FiltersStatusesEnum
  }
}
export const ticketUpdateStatusThunk = createAsyncThunk(
  'ticket/update',
  async ({ ticketId, payload }: UpdateTicketPayload, { rejectWithValue }) => {
    try {
      await Api.ticket.updateStatus(ticketId, payload)

      return {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getTicketsThunk = createAsyncThunk(
  'tickets/get',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.getTickets(url)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getTicketThunk = createAsyncThunk(
  'ticket/get',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.getTicket(id)

      return response?.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fireFinishTicket = createAsyncThunk(
  'ticket-finish/put',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Api.ticket.finishTicket(id)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
