import { FiltersStatusesEnum } from '@appTypes/enums/filters'
import { DialogAlertKeysEnum } from '@appTypes/enums/global'

import { COLORS } from '@collections/colors'
import { useAppDispatch } from '@store/hooks'
import { setPopUpData } from '@store/features/general/general.slice'
import { Button } from '@components/common'

export const ticketButtonsHook = ({ id, status }: { id: string; status: FiltersStatusesEnum }) => {
  const dispatch = useAppDispatch()

  const declineButton = () => {
    return (
      <Button
        type='button'
        color='secondary'
        variant='outlined'
        onClick={() => {
          dispatch(setPopUpData({ popUpType: DialogAlertKeysEnum.ticketDeclined, popUpId: id }))
        }}
      >
        Decline
      </Button>
    )
  }

  const counterButton = () => {
    return (
      <Button
        type='button'
        color='primary'
        variant='outlined'
        onClick={() => {
          dispatch(setPopUpData({ popUpType: DialogAlertKeysEnum.ticketCounter, popUpId: id }))
        }}
      >
        Counter
      </Button>
    )
  }

  const switchTitle = () => {
    switch (status) {
      case FiltersStatusesEnum.APPROVED:
        return 'Approved'
      case FiltersStatusesEnum.DECLINED:
        return 'Declined'
      case FiltersStatusesEnum.PENDING:
        return 'Pending'
      case FiltersStatusesEnum.APPROVED_CANCELED:
      case FiltersStatusesEnum.CANCELED: // only for admin
        return 'Cancelled'
      default:
        return ''
    }
  }

  const switchColor = () => {
    switch (status) {
      case FiltersStatusesEnum.APPROVED:
        return COLORS.green
      case FiltersStatusesEnum.DECLINED:
        return COLORS.red
      case FiltersStatusesEnum.APPROVED_CANCELED:
        return COLORS.raisinBlack
      default:
        return ''
    }
  }

  return { declineButton, counterButton, switchColor, switchTitle }
}
