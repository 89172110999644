import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'

import { FiltersStatusesEnum } from '@appTypes/enums/filters'
import { months, TimeZone, weekday } from '@constants/time'

interface ICollectDate {
  date: string
  endTime: string
  startTime: string
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(TimeZone)
dayjs.extend(relativeTime)

export const dayJsWrap = (iso: string) => {
  return dayjs(iso).tz(TimeZone)
}

export const generateEndDateByAvailability = (
  DateISO: string,
  availability: number
): dayjs.Dayjs => {
  return dayJsWrap(DateISO).add(availability, 'minute')
}

export const reformatDate = (dayjs: dayjs.Dayjs): string => {
  return dayjs.format('MM/DD/YYYY').replaceAll('/', '.')
}

export const reformatTime = (dayjs: dayjs.Dayjs): string => {
  return dayjs.format('hh:mm A')
}

export const collectDate = ({ date, startTime, endTime }: ICollectDate): React.ReactNode => {
  return (
    <span>
      {date}
      <br />
      {startTime} {endTime ? `- ${endTime}` : ''}
    </span>
  )
}

interface IFormatDate {
  date: string
  availability: number
  status?: FiltersStatusesEnum
  offerStatus?: string
  one?: boolean
}

export const formatDate = ({ date: DateISO, status, availability }: IFormatDate) => {
  const dateJs = dayJsWrap(DateISO)

  if (FiltersStatusesEnum.APPROVED === status) {
    return collectDate({
      date: reformatDate(dateJs),
      startTime: reformatTime(dateJs),
      endTime: ''
    })
  }

  const end = generateEndDateByAvailability(DateISO, availability)

  return collectDate({
    date: reformatDate(dateJs),
    startTime: reformatTime(dateJs),
    endTime: reformatTime(end)
  })
}

export const dateJSXWithMonthAndWeekDayNames = ({ date }: { date: string }) => {
  const dateJs = dayJsWrap(date)

  const day = dateJs.get('day')
  const month = dateJs.get('month')
  const dateNumber = dateJs.get('date')
  const year = dateJs.get('year')

  const dayTitle = weekday[day]
  const monthTitle = months[month]

  return (
    <span>
      {monthTitle} {dateNumber}, {year} ({dayTitle})
      <br />
      {dateJs.format('hh:mm A')}
    </span>
  )
}
