import { COLORS } from '@collections/colors'

const MuiAutocomplete = {
  styleOverrides: {
    root: {
      border: `1px solid ${COLORS.borderGray}`,
      borderRadius: '10px',

      '>.MuiFormControl-root': {
        maxHeight: 'inherit',
        '>.MuiInputBase-root': {
          padding: '5px 9px',
          maxHeight: 'inherit',

          '>.MuiInputBase-input': {
            padding: '0',
            background: COLORS.white,
            border: 'none',
            borderRadius: 0,
            height: 'auto',

            '&:-webkit-autofill': {
              '-webkit-box-shadow': `0 0 0 100px ${COLORS.white} inset`
            }
          },
          fieldset: {
            border: 'none'
          }
        }
      }
    }
  }
}

export default MuiAutocomplete
