import { forwardRef, memo, Ref } from 'react'

import InputBase, { InputBaseProps } from '@mui/material/InputBase'

interface IInput extends InputBaseProps {
  key?: string
  type?: string
  name?: string
  placeholder?: string
  register?: any
  error?: any
}

const Input = (props: IInput, ref: Ref<unknown>) => {
  return <InputBase ref={ref} {...props} />
}

export default memo(forwardRef(Input))
