import { memo, Dispatch, SetStateAction } from 'react'

import type { IOption } from '@appTypes/model/filters.model'

import Autocomplete from '@mui/material/Autocomplete'

import Close from '@svgs/close.svg'

import { useSuggestion } from './useSuggestion'
import { StyledIcon } from './styled'

interface ISuggestion {
  width?: string
  height?: string
  options: IOption[]
  placeholder: string
  inputValue: string
  selected: IOption | null
  setSelected: Dispatch<SetStateAction<IOption | null>>
  setInputValue: Dispatch<SetStateAction<string>>
}

const Suggestion = ({
  width,
  height,
  options,
  selected,
  inputValue,
  placeholder,
  setSelected,
  setInputValue
}: ISuggestion) => {
  const { handleChange, renderOption, renderInput } = useSuggestion({
    options,
    selected,
    setSelected
  })

  return (
    <Autocomplete
      sx={{ width, height }}
      freeSolo
      disablePortal
      clearIcon={<StyledIcon src={Close} />}
      inputValue={inputValue}
      value={selected}
      options={options}
      onChange={handleChange}
      renderOption={renderOption}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option?.name || ''
      }}
      renderInput={(params) => renderInput({ params, placeholder, setInputValue })}
    />
  )
}

export default memo(Suggestion)
