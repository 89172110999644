import { AxiosResponse } from 'axios'

import { http } from '@api/RequestApi'
import { ITicket } from '@appTypes/global'
import { IGetTicketsResponse, IUpdateStatus } from '@store/features/ticket/ticket.types'
import { BaseAPI } from './ApiServices'

class TicketService extends BaseAPI {
  private prefix = '/tickets'

  constructor() {
    super()
  }

  public async getTickets(url: string) {
    BaseAPI.isUserExist()

    const response = await http.get<any, AxiosResponse<IGetTicketsResponse>>(
      `${this.prefix}?${url}`
    )
    return response?.data
  }

  public async getTicket(id: string) {
    const response = await http.get<any, AxiosResponse<{ data: ITicket }>>(
      `${this.prefix}/${id}/info`
    )

    return response?.data
  }

  public async updateStatus(id: string, data: IUpdateStatus) {
    const response = await http.put<any, AxiosResponse<IGetTicketsResponse>>(
      `${this.prefix}/${id}/status`,
      data
    )

    return response?.data
  }

  public async finishTicket(id: string) {
    const response = await http.put<void, AxiosResponse<IGetTicketsResponse>>(
      `${this.prefix}/${id}/finish`
    )

    return response?.data
  }
}

export default TicketService
