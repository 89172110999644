import { styled } from '@mui/material/styles'
import { RouteEnum } from '@appTypes/enums/global'
import { Box, Toolbar } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { COLORS } from '@collections/colors'

export const PagesBox = styled(Box)(({ theme }) => ({
  display: 'flex',

  a: {
    width: '100%',
    padding: '20px',
    textAlign: 'center'
  },

  '.MuiBox-root': {
    width: '180px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `1px solid ${COLORS.bordersInHeader}`
  },

  '.MuiOutlinedInput-root': {
    borderRadius: 0,
    borderLeft: `1px solid ${COLORS.bordersInHeader}`,

    '.MuiSvgIcon-root': {
      right: '17px'
    },
    '.MuiSelect-select': {
      border: 'none',
      height: '75px',
      padding: '26px 35px',
      maxHeight: 'unset',
      width: '180px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '130%',
      letterSpacing: '-0.01em',
      color: COLORS.raisinBlack
    },
    '> input': {
      display: 'none'
    }
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'static',
  color: 'black',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,

  '.MuiContainer-root': {
    padding: '0px',

    '> .MuiToolbar-root': {
      padding: '0',
      minHeight: 'unset'
    }
  },

  [theme.breakpoints.down('xl')]: {
    '.MuiContainer-root': {
      '.MuiToolbar-root': {
        padding: '0 25px',
        height: 'auto'
      }
    }
  }
}))

export const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  display: 'grid',
  // gridTemplateColumns: '1fr auto 0px 212px',
  gridTemplateColumns: '1fr auto 0px',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 0px 212px'
  }
}))

export const LogoWrapper = styled(Box)<{ type: RouteEnum }>(({ type }) => ({
  flexGrow: 1,
  margin: '25px 0',
  height: type === RouteEnum.PRIVATE ? '25px' : '30px',

  img: {
    height: type === RouteEnum.PRIVATE ? '25px' : '30px'
  }
}))

export const Bold = styled('span')(() => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '130%',
  letterSpacing: '-0.01em'
}))

export const LineWrapper = styled(Box)(() => ({
  position: 'relative'
}))

export const Line = styled(Box)(() => ({
  position: 'absolute',
  display: 'inherit',
  left: '0',
  top: '-38px',
  height: '75px',
  width: '0.1px',
  background: COLORS.borderBottomInSelect
}))
